import sass from "../scss/main.scss";
import Tabby from "tabbyjs";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';


// Плавное появление на первом экране

$(document).ready(function () {

  // Прелоадер
  if ($('.preloader').length) {
    $('.preloader').fadeOut();
  }

  // Анимация первый экран
  $(".intro__content").addClass("animation-text");
  $(".intro__wrap").addClass("animation-img");

  // Слайдер на первом экране
  $(".intro__wrap").owlCarousel({
    dots: false,
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000,
    animateOut: 'fadeOut'
  });

  // Скролл к якорю
  if ($('.scroll').length) {
    $(".scroll").on('click', function () {
      var target = $(this).attr('href');
      // animate
      $('html, body').animate({
        scrollTop: $(target).offset().top
      }, 1000);

      return false;

    });
  }

  // Вкладки
  const tabs = new Tabby('[data-tabs]', {
    default: '[data-tabby-default]' // The selector to use for the default tab
  });

});
